module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ergo-creative-2023","short_name":"ergo","start_url":"/","background_color":"#ffffff","theme_color":"#0B0B0B","display":"minimal-ui","icon":"src/images/favicon.svg","cache_busting_mode":"query","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"29a9343613bc43c858b2ee29cdf997f6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"crumbSeparator":" > ","autoGenHomeLabel":"home","exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"-"},"trailingSlashes":false},
    },{
      plugin: require('../node_modules/gatsby-styled-components-dark-mode/gatsby-browser.js'),
      options: {"plugins":[],"light":{"body":"#ffffff","text":"#0B0B0B","grey":"#0b0b0b1f","lightgrey":"#0B0B0B05","mediumgrey":"#0B0B0Bc7","darkGrey":"#0b0b0bf0","highlight":"#257580","alwaysDark":"#0B0B0B","alwaysLight":"#ffffff","success":"#37BFA7","info":"#FF9678","error":"#F54768","boxshadow":"0px 0px 19px 0px rgba(0,0,0,0.2)"},"dark":{"body":"#0B0B0B","text":"#ffffff","grey":"#ffffff1f","lightgrey":"#ffffff05","mediumgrey":"#ffffffc7","darkGrey":"#fffffff0","highlight":"#39B0BF","alwaysDark":"#0B0B0B","alwaysLight":"#ffffff","success":"#37BFA7","info":"#FF9678","error":"#F54768","boxshadow":"0px 0px 19px 0px rgba(255,255,255,0.2)"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
